@charset "UTF-8";
@import "mixin", "base";

/* ==============================
		  common.scss
================================= */

/*---------------------------------------------------------
	全ページ共通スタイル
---------------------------------------------------------*/
* {
  box-sizing: border-box;
}



html {
  /* 1rem = 10px */
  font-size: 62.5%;
  background: $white;

}

/* 要素設定*/
body {
  font-family: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 300;
  color: $black;
  letter-spacing: 0.05em;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: “palt” 1;
  font-stretch: 95%;
  line-height: 1.75;
  text-align: justify;
  overflow-x: hidden;
  font-weight: 400;
}

body.android {
  font-family: "Noto Serif JP", serif;
  font-weight: 400;
}

button,
a {
  color: $black;
}

.bg-red {
  background: $main_color;
  color: $white;
}

.bg-white {
  background: $white;
}

.bg-gray {
  background: $gray;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.animated {
  opacity: 0;
}

.midashi {
  text-align: center;
  margin-bottom: 6.4rem;
  color: $main_color;

  &::before {
    content: "";
    position: absolute;
    top: -3.2rem;
    left: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 60% 40% 60% 40% / 60% 40% 60% 40%;
    background: $main_color;

    margin: 0 auto;
    font-size: 0.5em;
  }
}

.bg-red .midashi {
  color: $white;

  &::before {
    background: $white;
  }
}

a[target]::after {
  content: "";
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  margin-left: .8rem;
  background-image: url(../img/common/icon/icon-0.svg);
  background-repeat: no-repeat;
}

.nav__list__items a[target]::after {
  display: none;
}


.midashi[data-ruby] {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 10vw;
  font-weight: 300;
  line-height: 1;
  letter-spacing: .08em;
}

.midashi[data-ruby]::after {
  content: attr(data-ruby);
  position: absolute;
  bottom: -2.4rem;
  left: 0;
  right: 0;
  margin: auto;
  font-weight: lighter;
  font-size: 1.3rem;

}

.komidashi[data-ruby] {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  position: relative;
  font-size: 2.4rem;
}

.komidashi[data-ruby]::after {
  content: attr(data-ruby);
  position: absolute;
  bottom: -1.2em;
  left: 0;
  right: 0;
  margin: auto;
  font-weight: lighter;
  font-size: 1.2rem;
}

.red_button {
  font-family: 'Montserrat', sans-serif;
  background: $main_color;
  color: $white;
  line-height: 1;
  display: inline-block;
  margin: 0 auto;
  padding: 1.6rem 1.6rem;
  text-align: center;
  letter-spacing: .2em;
  border-radius: 5rem;
  width: 100%;
  border: 1px solid $main_color;


  .arrow {
    width: 50px;
    position: absolute;
    transform: scale(.5, 1);
    transition-duration: 50ms;
    transition-timing-function: ease;
    background-image: url(../img/common/icon/icon-22.svg);
    background-position: top right;
    background-size: cover;
    width: 5em;
    height: 1em;
  }

  &:hover {
    padding-right: 5.8rem;

    .arrow {
      display: inline-block;
      content: "";
      width: 87px;
      background-image: url(../img/common/icon/icon-28.svg);

      &::after {
        border-bottom: 1px solid $main_color;
        margin-left: 2.1rem;

      }
    }

  }

  &:hover {
    background: inherit;
    color: $main_color;
    border: 1px solid $main_color;
  }
}

.border_button {
  border: 1px solid $white;
  padding: 1.6rem 2.4rem;
  color: $white;
  border-radius: 7rem;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  &.tel {
    &::before {
      content: "";
      display: inline-block;
      width: 4rem;
      height: 4rem;
      background-image: url(../img/common/icon/icon-20.svg);
      background-size: cover;
      margin-right: .8rem;
    }
  }

  //    &:hover{
  //      background: $white;
  //      color:$main_color;
  //    }

  &.form {
    background-image: url(../img/common/icon/icon-22.svg);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 85% 75%;

    @media screen and (min-width: 560px) {
      background-size: 21%;
      background-position: 64% 75%;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 4.6rem;
      height: 3.1rem;
      background-image: url(../img/common/icon/icon-21.svg);
      background-size: cover;
      margin-right: .8rem;
    }
  }

}




.wrap_inner {
  padding: 8rem 1.6rem 5.6rem;
  max-width: 1000px;
  margin: 0 auto;
}

.inview_box {
  opacity: 0;
  transition: all 1000ms ease;
  position: relative;
  z-index: 1;
  top: 50px;
}

.inview_box_on {
  opacity: 1;
  top: 0px;
}

a.pagetop {
  position: fixed;
  z-index: 10000;
  bottom: -10rem;
  right: 1rem;
  background: $white;
  display: flex;
  width: 6rem;
  height: 6rem;
  border-radius: 60% 40% 60% 40% / 60% 40% 60% 40%;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray;
}

.concept {
  text-align: center;
  letter-spacing: .25em;

  p {
    margin-bottom: .8em;
    line-height: 2;
  }
}

.fb {
  font-weight: bold;
}

/*---------------------------------------------------------
	ローディング画面
---------------------------------------------------------*/
.loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  background: $main_color;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;

  .loader {

    p {
      margin-top: 1rem;
    }
  }

}

.-inactive {}



/*---------------------------------------------------------
	ヘッダー
---------------------------------------------------------*/
#pageHeader {
  width: 100%;
  background-color: $white;
  height: 7.6rem;

  .header-inner {
    width: 100%;
    padding: .8rem;
    line-height: 2;
    display: flex;
    justify-content: space-between;

    h1 {
      height: 6rem;
      display: flex;
      align-items: center;

      p {
        line-height: 1.4;
        font-size: 1.3rem;
        margin-left: 1.6rem;
        font-weight: 500;
      }

      img {
        height: 6rem;
      }
    }
  }

  .nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 200vh;
    height: 200vh;
    z-index: 10000;
    background: $main_color;
    color: $white;
    overflow: hidden;
    transition: all .3s ease-out;
    transform: scale(0);
    display: block;
    transform-origin: right top;
    border-radius: 50% 0% 60% 40% / 60% 40% 60% 40%;

    &__list {
      display: block;
      padding: 5rem 1.6rem;

      &__items {
        display: none;


        &.animated {
          display: block;
        }

        a {
          display: block;
          border-bottom: 1px solid #fff;
          width: calc(100vw - 3.2rem);
          padding: .8rem 0;
          color: $white;
        }


        &:nth-child(1) {
          animation-delay: 0ms;
        }

        &:nth-child(2) {
          animation-delay: 75ms;
        }

        &:nth-child(3) {
          animation-delay: 150ms;
        }

        &:nth-child(4) {
          animation-delay: 225ms;
        }

        &:nth-child(5) {
          animation-delay: 300ms;
        }

        &:nth-child(6) {
          animation-delay: 375ms;
        }

        &:nth-child(7) {
          animation-delay: 450ms;
        }

        &.twitter {
          a {
            border-bottom: none;
            text-align: center;
            margin-top: 1.6rem;
          }

          img {
            width: 4.4rem;
          }
        }

      }
    }
  }


}

body.open {
  overflow: hidden;
}

body.open .nav {
  transform: scale(1) !important;
  transition: all .6s ease-out;
  border-radius: 0% 0% 60% 40% / 60% 40% 60% 40% !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  animation-delay: .3s;

  &__list {}
}

.menu {
  position: fixed;
  z-index: 10001;
  top: 1rem;
  right: 1rem;
  background: $main_color;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 60% 40% 60% 40% / 60% 40% 60% 40%;
}

.p-hamburger__line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 18px;
  height: 2px;
  background-color: #f9d8ae;
  -webkit-transition: inherit;
  transition: inherit;
}

.p-hamburger__line::before,
.p-hamburger__line::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: inherit;
  -webkit-transition: inherit;
  transition: inherit;
}

.p-hamburger__line::before {
  top: -5px;
}

.p-hamburger__line::after {
  top: 5px;
}

.p-drawer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  -webkit-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
}

.open .p-drawer {
  transform: translateX(0);
}

.u-visuallyHidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.menu[aria-expanded="true"] .p-hamburger__line {
  background-color: transparent;
}

.menu[aria-expanded="true"] .p-hamburger__line::before,
.menu[aria-expanded="true"] .p-hamburger__line::after {
  top: 0;
  background-color: #f9d8ae;
}

.menu[aria-expanded="true"] .p-hamburger__line::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu[aria-expanded="true"] .p-hamburger__line::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* responsive */


.sp-none {
  display: none;
}

.sp-inline {
  display: inline;
}

.sp-block {
  display: block;
}

.subpage-image {
  height: 400px;
  width: 100%;
  background-image: url(https://placehold.jp/600x600.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column;
  padding: 0 1.6rem;
  text-shadow: 0 0 20px rgba(0, 0, 0, .3);
  color: $white;

  h2 {
    border-bottom: $white 1px solid;
    font-family: 'Montserrat', sans-serif;
    font-size: 13vw;
    letter-spacing: .4rem;
    line-height: 1;
  }


}

.delay-100ms {
  animation-delay: 200ms;
}

/* --------------- section-contact --------------- */
.section-contact {}

.p-contact .section-contact {
  display: none;
}

.contact {
  .text {
    text-align: center;
    margin-bottom: 2.4rem;
  }

  .contents {
    display: flex;
    flex-wrap: wrap;

    &__button {
      width: 100%;

      &:first-child {
        margin-bottom: 7.4rem;
      }

      .annotation {
        font-size: 1.2rem;
        line-height: 1.4;
        margin: .8rem 0 .8rem;
        position: relative;

        &::before {
          content: "※";
        }

        span {
          position: absolute;
          left: 1.2em;
        }
      }
    }

  }
}

#select,
.select-options {
  display: none;
}

.ppolicy {
  p {
    margin-bottom: 1.6rem;
  }

  ol {
    list-style: circle;

    li {
      margin-bottom: 1.6rem;
    }
  }

}

.high-foon {
  display: none;
}

.r-high-foon {
  display: block;
}

.concept p.spline {
  margin-bottom: 0;
}

/* =========================== tablet版 =========================== */

@media screen and (min-width: 560px) {

  .concept p.spline {
    margin-bottom: .8em;
  }

  .high-foon {
    display: inline;
  }

  .r-high-foon {
    display: none;
  }

  .red_button .arrow {
    width: 3em;
  }

  .subpage-image {
    h2 {
      font-size: 7rem;
      line-height: 1;
    }
  }

  .midashi[data-ruby] {
    font-size: 4rem;
    letter-spacing: .2em;
  }

}

/* =========================== PC版 =========================== */
@media screen and (min-width: 960px) {
  .sp-none {
    display: block;
  }

  .sp-inline,
  .sp-block {
    display: none;
  }

  .wrap_inner {
    padding: 12rem 3.2rem 6.4rem;
  }

  body.open {
    overflow: visible;
  }

  .concept {
    font-size: 18px;
  }

  #pageHeader {
    box-shadow: 0 0px 3px rgba(0, 0, 0, .3);
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;

    .header-inner {
      padding: .8rem 1.6rem;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      z-index: 10000;
      background: $white;
      height: 7.6rem;
      overflow: inherit;
      align-items: center;

    }

    .midashi[data-ruby] {
      font-size: 4rem;
      letter-spacing: .2em;
    }

    .nav {
      position: relative;
      width: auto;
      height: auto;
      z-index: 2;
      background: initial;
      color: $black;
      overflow: inherit;
      transition: none;
      transform: scale(1);
      display: block;
      transform-origin: right top;
      border-radius: 0;

      &__list {
        display: flex;
        padding: 0;
        background: $white;

        &__items {
          position: relative;
          display: inline-block;
          line-height: 1;
          margin-top: 1.2rem;
          margin-left: 1.6rem;
          text-align: center;

          &::after {
            position: absolute;
            bottom: .1rem;
            left: 0;
            content: '';
            width: 100%;
            height: 2px;
            background: $main_color;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform .3s;
          }

          &:hover {

            &::after {
              transform: scale(1, 1);
            }
          }


          a {
            display: inline-block;
            border-bottom: none;
            width: 100%;
            padding: .8rem 0;
            color: $black;
            transition-duration: 150ms;
            transition-timing-function: ease;

            .ruby {
              margin-top: 1.6rem;
              display: block;
            }
          }



          &.rubyhov {
            height: 3.2rem;
            overflow: hidden;

            a {
              position: relative;
              top: 0;
            }

            &:hover a {
              top: -3rem;
            }
          }

          &.blog {
            margin: 0 10rem 0 1rem;
            text-align: center;
            position: relative;


            a {
              color: $white;
              top: -2.2rem;
              position: absolute;
              background: $main_color;
              border-radius: 60% 40% 60% 40% / 60% 40% 60% 40%;
              width: 9.8rem;
              height: 9.8rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background-repeat: no-repeat;
              background-position: 14px 57px;
              background-size: 70px;

              .hov {
                top: -.4rem;
              }
            }

            &:hover a {
              background-image: url(../img/common/icon/icon-29.svg);
              background-repeat: no-repeat;
              background-position: 14px 57px;
              background-size: 70px;


              .hov {
                position: relative;
                top: 0;
                transition-duration: 150ms;
                transition-timing-function: ease;
              }

            }
          }



          &.twitter {
            margin-top: 0rem;

            a {
              margin-top: .8rem;
            }

            &::after {
              height: 0;
            }

            img {
              width: 2.4rem;
            }
          }

          &:nth-child(1) {
            animation-delay: 0ms;
          }

          &:nth-child(2) {
            animation-delay: 75ms;
          }

          &:nth-child(3) {
            animation-delay: 150ms;
          }

          &:nth-child(4) {
            animation-delay: 225ms;
          }

          &:nth-child(5) {
            animation-delay: 300ms;
          }

          &:nth-child(6) {
            animation-delay: 375ms;
          }

          &:nth-child(7) {
            animation-delay: 450ms;
          }

        }
      }
    }


  }

  #pageMain {
    margin-top: 7.6rem;
  }

  /* --------------- section-contact --------------- */
  .section-contact {}

  .contact {
    .text {}

    .contents {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      &__button {
        width: calc(50% - 1.6rem);
        max-width: 64rem;

        &:first-child {}

        .annotation {

          &::before {}
        }
      }

    }
  }

  .border_button {
    padding: 4rem 3.2rem;
    font-size: 2rem;
    @include transition(all, 0.5s);

    &:hover {
      background-color: $white;
      color: $main_color;
      background-repeat: no-repeat;
    }

    &:hover::before {
      background-image: url(../img/common/icon/icon-13.svg);
    }

    &.form {
      background-size: inherit;
      background-position: 74% 78%;

      &:hover {
        background-color: $white;
        color: $main_color;
        background-image: url(../img/common/icon/icon-28.svg);
        background-repeat: no-repeat;
      }

      &:hover::before {
        background-image: url(../img/common/icon/icon-35.svg);
      }

    }



  }

  .contact .text {
    font-weight: 400;
    font-size: 2.4rem;
  }

  .subpage-image {
    height: 531px;

    h2 {
      font-size: 9rem;
      line-height: 1;
    }

    p {
      font-size: 20px;
    }
  }


}

@media screen and (min-width: 1300px) {
  #pageHeader {

    .header-inner {}
  }
}
